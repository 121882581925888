import request from '@/utils/request'
const requestApi = {
  wms_goods_purchase_list: '/wms/warehouse/goods_purchase/',
  wms_goods_purchase_create: '/wms/warehouse/goods_purchase/',
  wms_goods_purchase_update: '/wms/warehouse/goods_purchase/',
  wms_goods_purchase_delete: '/wms/warehouse/goods_purchase/',
  wms_goods_purchase_import: '/wms/warehouse/goods_purchase_import/',
  wms_goods_purchase_download: '/wms/warehouse/goods_purchase_download/',
  goods_purchase_declaration_list: '/wms/warehouse/goods_purchase_declaration/',
  goods_purchase_declaration_create: '/wms/warehouse/goods_purchase_declaration/',
  goods_purchase_declaration_delete: '/wms/warehouse/goods_purchase_declaration/',
  wms_custom_goods_purchase_create: '/wms/warehouse/custom_goods_purchase/'
}

export function goods_purchase_list (query) {
  return request({
    url: requestApi.wms_goods_purchase_list,
    method: 'get',
    params: query
  })
}

export function goods_purchase_create (data) {
  return request({
    url: requestApi.wms_goods_purchase_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function custom_goods_purchase_create (data) {
  return request({
    url: requestApi.wms_custom_goods_purchase_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function goods_purchase_update (data, goods_purchase_id) {
  return request({
    url: requestApi.wms_goods_purchase_update + goods_purchase_id + '/',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}
export function goods_purchase_delete (goods_purchase_id) {
  return request({
    url: requestApi.wms_goods_purchase_delete + goods_purchase_id + '/',
    method: 'delete'
  })
}

export function goods_purchase_import (parameter) {
  return request({
    url: requestApi.wms_goods_purchase_import,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: parameter
  })
}

export function download_goods_purchase_template_excel (parameter) {
  return request({
    url: requestApi.wms_goods_purchase_download,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}

export function goods_purchase_declaration_list (query) {
  return request({
    url: requestApi.goods_purchase_declaration_list,
    method: 'get',
    params: query
  })
}

export function goods_purchase_declaration_create (data) {
  return request({
    url: requestApi.goods_purchase_declaration_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function goods_purchase_declaration_delete (goods_purchase_id) {
  return request({
    url: requestApi.goods_purchase_declaration_delete + goods_purchase_id + '/',
    method: 'delete'
  })
}
